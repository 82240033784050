buttons:
  pureAccountSettings: 账户设置
  pureLoginOut: 退出系统
  pureLogin: 登录
  pureOpenSystemSet: 打开系统配置
  pureReload: 重新加载
  pureCloseCurrentTab: 关闭当前标签页
  pureCloseLeftTabs: 关闭左侧标签页
  pureCloseRightTabs: 关闭右侧标签页
  pureCloseOtherTabs: 关闭其他标签页
  pureCloseAllTabs: 关闭全部标签页
  pureContentFullScreen: 内容区全屏
  pureContentExitFullScreen: 内容区退出全屏
  pureClickCollapse: 点击折叠
  pureClickExpand: 点击展开
  pureConfirm: 确认
  pureSwitch: 切换
  pureClose: 关闭
  pureBackTop: 回到顶部
  pureOpenText: 开
  pureCloseText: 关
search:
  pureTotal: 共
  pureHistory: 搜索历史
  pureCollect: 收藏
  pureDragSort: （可拖拽排序）
  pureEmpty: 暂无搜索结果
  purePlaceholder: 搜索菜单（支持拼音搜索）
panel:
  pureSystemSet: 系统配置
  pureCloseSystemSet: 关闭配置
  pureClearCacheAndToLogin: 清空缓存并返回登录页
  pureClearCache: 清空缓存
  pureOverallStyle: 整体风格
  pureOverallStyleLight: 浅色
  pureOverallStyleLightTip: 清新启航，点亮舒适的工作界面
  pureOverallStyleDark: 深色
  pureOverallStyleDarkTip: 月光序曲，沉醉于夜的静谧雅致
  pureOverallStyleSystem: 自动
  pureOverallStyleSystemTip: 同步时光，界面随晨昏自然呼应
  pureThemeColor: 主题色
  pureLayoutModel: 导航模式
  pureVerticalTip: 左侧菜单，亲切熟悉
  pureHorizontalTip: 顶部菜单，简洁概览
  pureMixTip: 混合菜单，灵活多变
  pureStretch: 页宽
  pureStretchFixed: 固定
  pureStretchFixedTip: 紧凑页面，轻松找到所需信息
  pureStretchCustom: 自定义
  pureStretchCustomTip: 最小1280、最大1600
  pureTagsStyle: 页签风格
  pureTagsStyleSmart: 灵动
  pureTagsStyleSmartTip: 灵动标签，添趣生辉
  pureTagsStyleCard: 卡片
  pureTagsStyleCardTip: 卡片标签，高效浏览
  pureInterfaceDisplay: 界面显示
  pureGreyModel: 灰色模式
  pureWeakModel: 色弱模式
  pureHiddenTags: 隐藏标签页
  pureHiddenFooter: 隐藏页脚
  pureMultiTagsCache: 页签持久化
menus:
  pureHome: 首页
  pureLogin: 登录
  pureEmpty: 无Layout页
  pureTable: 表格
  pureSysManagement: 系统管理
  pureUser: 用户管理
  pureRole: 角色管理
  pureSystemMenu: 菜单管理
  pureDept: 部门管理
  pureSysMonitor: 系统监控
  pureOnlineUser: 在线用户
  pureLoginLog: 登录日志
  pureOperationLog: 操作日志
  pureSystemLog: 系统日志
  pureEditor: 编辑器
  pureAbnormal: 异常页面
  pureFourZeroFour: "404"
  pureFourZeroOne: "403"
  pureFive: "500"
  pureComponents: 组件
  pureDialog: 函数式弹框
  pureMessage: 消息提示
  pureVideo: 视频
  pureSegmented: 分段控制器
  pureWaterfall: 瀑布流无限滚动
  pureMap: 地图
  pureDraggable: 拖拽
  pureSplitPane: 切割面板
  pureText: 文本省略
  pureElButton: 按钮
  pureCheckButton: 可选按钮
  pureButton: 按钮动效
  pureCropping: 图片裁剪
  pureAnimatecss: animate.css选择器
  pureCountTo: 数字动画
  pureSelector: 范围选择器
  pureFlowChart: 流程图
  pureSeamless: 无缝滚动
  pureContextmenu: 右键菜单
  pureTypeit: 打字机
  pureJsonEditor: JSON编辑器
  pureColorPicker: 颜色选择器
  pureDatePicker: 日期选择器
  pureDateTimePicker: 日期时间选择器
  pureTimePicker: 时间选择器
  pureTag: 标签
  pureStatistic: 统计组件
  pureCollapse: 折叠面板
  pureGanttastic: 甘特图
  pureProgress: 进度条
  pureUpload: 文件上传
  pureCheckCard: 多选卡片
  pureMenus: 多级菜单
  pureMenu1: 菜单1
  pureMenu1-1: 菜单1-1
  pureMenu1-2: 菜单1-2
  pureMenu1-2-1: 菜单1-2-1
  pureMenu1-2-2: 菜单1-2-2
  pureMenu1-3: 菜单1-3
  pureMenu2: 菜单2
  purePermission: 权限管理
  purePermissionPage: 页面权限
  purePermissionButton: 按钮权限
  pureTabs: 标签页操作
  pureGuide: 引导页
  pureAble: 功能
  pureMenuTree: 菜单树结构
  pureVideoFrame: 视频帧截取-wasm版
  pureWavesurfer: 音频可视化
  pureRipple: 波纹(Ripple)
  pureMqtt: MQTT客户端(mqtt)
  pureOptimize: 防抖、截流、复制、长按指令
  pureVerify: 图形验证码
  pureWatermark: 水印
  purePrint: 打印
  pureDownload: 下载
  pureExternalPage: 外部页面
  pureExternalDoc: 文档外链
  pureEmbeddedDoc: 文档内嵌
  pureExternalLink: vue-pure-admin
  pureUtilsLink: pure-admin-utils
  pureColorHuntDoc: 调色板
  pureUiGradients: 渐变色
  pureEpDoc: element-plus
  pureTailwindcssDoc: tailwindcss
  pureVueDoc: vue3
  pureViteDoc: vite
  purePiniaDoc: pinia
  pureRouterDoc: vue-router
  pureAbout: 关于
  pureResult: 结果页面
  pureSuccess: 成功页面
  pureFail: 失败页面
  pureIconSelect: 图标选择器
  pureTimeline: 时间线
  pureLineTree: 树形连接线
  pureList: 列表页面
  pureCardList: 卡片列表页
  pureDebounce: 防抖节流
  pureFormDesign: 表单设计器
  pureBarcode: 条形码
  pureQrcode: 二维码
  pureCascader: 区域级联选择器
  pureSwiper: Swiper插件
  pureVirtualList: 虚拟列表
  purePdf: PDF预览
  pureExcel: 导出Excel
  pureInfiniteScroll: 表格无限滚动
  pureSensitive: 敏感词过滤
  purePinyin: 汉语拼音
  pureDanmaku: 弹幕
  pureSchemaForm: 表单
  pureTableBase: 基础用法
  pureTableHigh: 高级用法
  pureTableEdit: 可编辑用法
  pureVxeTable: 虚拟滚动
  pureBoard: 艺术画板
  pureMindMap: 思维导图
  pureMenuOverflow: 目录超出显示 Tooltip 文字提示
  pureChildMenuOverflow: 菜单超出显示 Tooltip 文字提示
status:
  pureLoad: 加载中...
  pureMessage: 消息
  pureNotify: 通知
  pureTodo: 待办
  pureNoMessage: 暂无消息
  pureNoNotify: 暂无通知
  pureNoTodo: 暂无待办
login:
  pureUsername: 账号
  purePassword: 密码
  pureVerifyCode: 验证码
  pureRemember: 天内免登录
  pureRememberInfo: 勾选并登录后，规定天数内无需输入用户名和密码会自动登入系统
  pureSure: 确认密码
  pureForget: 忘记密码?
  pureLogin: 登录
  pureThirdLogin: 第三方登录
  purePhoneLogin: 手机登录
  pureQRCodeLogin: 二维码登录
  pureRegister: 注册
  pureWeChatLogin: 微信登录
  pureAlipayLogin: 支付宝登录
  pureQQLogin: QQ登录
  pureWeiBoLogin: 微博登录
  purePhone: 手机号码
  pureSmsVerifyCode: 短信验证码
  pureBack: 返回
  pureTest: 模拟测试
  pureTip: 扫码后点击"确认"，即可完成登录
  pureDefinite: 确定
  pureLoginSuccess: 登录成功
  pureLoginFail: 登录失败
  pureRegisterSuccess: 注册成功
  pureTickPrivacy: 请勾选隐私政策
  pureReadAccept: 我已仔细阅读并接受
  purePrivacyPolicy: 《隐私政策》
  pureGetVerifyCode: 获取验证码
  pureInfo: 秒后重新获取
  pureUsernameReg: 请输入账号
  purePassWordReg: 请输入密码
  pureVerifyCodeReg: 请输入验证码
  pureVerifyCodeCorrectReg: 请输入正确的验证码
  pureVerifyCodeSixReg: 请输入6位数字验证码
  purePhoneReg: 请输入手机号码
  purePhoneCorrectReg: 请输入正确的手机号码格式
  purePassWordRuleReg: 密码格式应为8-18位数字、字母、符号的任意两种组合
  purePassWordSureReg: 请输入确认密码
  purePassWordDifferentReg: 两次密码不一致!
  purePassWordUpdateReg: 修改密码成功
