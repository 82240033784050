import { http } from "@/utils/http";

type Result = {
  success: boolean;
  data: Array<any>;
};

export const getAsyncRoutes = () => {
  function addMeta(list: Array<any>, parent: any) {
    list.forEach(child => {
      if (child.menuType == 1) {
        if (child.path?.includes("/label/insert")) {
          parent.meta.btns.push("labelInsert");
        } else if (child.path?.includes("/label/update")) {
          parent.meta.btns.push("labelUpdate");
        } else if (child.path?.includes("/label/delete")) {
          parent.meta.btns.push("labelDelete");
        } else if (child.path?.includes("/insert")) {
          parent.meta.btns.push("insert");
        } else if (child.path?.includes("/update")) {
          parent.meta.btns.push("update");
        } else if (child.path?.includes("/delete")) {
          parent.meta.btns.push("delete");
        } else if (child.path?.includes("/export")) {
          parent.meta.btns.push("export");
        }
      }
      child.menuId = child.id;
      child.parentMenuId = child.parentId;
      if (child.component) {
        child.component = child.component.replace("@", "/src");
      }
      child.name = child.menuName;
      child.meta = {
        title: child.menuName,
        showLink: !child.menuType,
        icon: child.icon,
        btns: []
      };
      if (child.children?.length) {
        addMeta(child.children, child);
      }
    });
  }
  return new Promise(resolve => {
    http.request<Result>("get", `api/v1/cms/getMenuByUser`).then(res => {
      // res.data = [
      //   res.data[0],
      //   // res.data[1],
      //   res.data[2],
      //   res.data[3],
      //   res.data[4],
      //   res.data[5],
      //   res.data[6]
      // ];
      addMeta(res.data, {});
      resolve(res);
    });
  });
  // return http.request<Result>("get", `api/v1/cms/getRoleList?roleId=${roleId}`);
};
