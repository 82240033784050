// 完整版菜单比较多，将 rank 抽离出来，在此方便维护

const home = 0, // 平台规定只有 home 路由的 rank 才能为 0 ，所以后端在返回 rank 的时候需要从非 0 开始
  vueflow = 1,
  ganttastic = 2,
  components = 3,
  able = 4,
  table = 5,
  form = 6,
  list = 7,
  result = 8,
  error = 9,
  frame = 10,
  nested = 11,
  permission = 12,
  system = 13,
  monitor = 14,
  tabs = 15,
  about = 16,
  editor = 17,
  flowchart = 18,
  formdesign = 19,
  board = 20,
  ppt = 21,
  mind = 22,
  guide = 23,
  menuoverflow = 24;

export {
  home,
  vueflow,
  ganttastic,
  components,
  able,
  table,
  form,
  list,
  result,
  error,
  frame,
  nested,
  permission,
  system,
  monitor,
  tabs,
  about,
  editor,
  flowchart,
  formdesign,
  board,
  ppt,
  mind,
  guide,
  menuoverflow
};
